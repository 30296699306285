import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Slide,
    PaperProps,
    SlideProps,
} from '@mui/material';
import React from 'react';
import { useUIContext } from '../contexts/UIContext';
import Draggable from 'react-draggable';

// Define types for the dialog object
export interface DialogObject {
    open: boolean;
    title: string;
    description: string;
    content: React.ReactNode | null; // Allows for JSX content
    ok: string;
    cancel: string;
    call_on_submit?: () => void;
    call_on_cancel?: () => void;
}

// Default dialog values
export const DEFAULT_DIALOG_BUTTONS = { ok: 'OK', cancel: 'CANCEL' };
export const DEFAULT_DIALOG_PARAMETERS = {
    title: 'Title',
    description: 'Description!',
    content: null,
};
export const DEFAULT_DIALOG_HANDLERS = {
    call_on_submit: undefined,
    call_on_cancel: undefined,
};
export const HIDDEN_DIALOG_OBJECT: DialogObject = {
    open: false,
    ...DEFAULT_DIALOG_HANDLERS,
    ...DEFAULT_DIALOG_BUTTONS,
    ...DEFAULT_DIALOG_PARAMETERS,
};

// Paper component for draggable dialog
function PaperComponent(props: PaperProps) {
    // Use PaperProps here
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[className*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

// Transition for the dialog
const Transition = React.forwardRef(function Transition(
    props: SlideProps,
    ref: React.Ref<unknown>,
) {
    // Use SlideProps here
    return <Slide direction="up" ref={ref} {...props} />;
});

// Define the DialogPopupContainer component
const DialogPopupContainer: React.FC = () => {
    const { dialogObject, setDialogObject } = useUIContext();

    const handleClose = (
        event: React.MouseEvent | React.KeyboardEvent,
        reason?: string,
    ) => {
        if (reason === 'backdropClick') return;
        if (reason === 'clickaway') return;

        const { call_on_cancel } = dialogObject;
        setDialogObject({ ...dialogObject, open: false });
        if (call_on_cancel) call_on_cancel();
    };

    const handleSubmit = (
        event: React.MouseEvent | React.KeyboardEvent,
        reason?: string,
    ) => {
        event.preventDefault();
        if (reason === 'clickaway') return;

        const { call_on_submit } = dialogObject;
        setDialogObject({ ...dialogObject, open: false });
        if (call_on_submit) call_on_submit();
    };

    return (
        <div>
            <Dialog
                open={dialogObject.open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                disableEscapeKeyDown
            >
                <DialogTitle
                    style={{ cursor: 'move' }}
                    id="draggable-dialog-title"
                >
                    {dialogObject.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogObject.description}
                    </DialogContentText>
                    <hr />
                    {dialogObject.content}
                </DialogContent>
                {dialogObject.ok || dialogObject.cancel ? (
                    <DialogActions>
                        <hr />
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            color="secondary"
                        >
                            {dialogObject.cancel}
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            color="primary"
                        >
                            {dialogObject.ok}
                        </Button>
                    </DialogActions>
                ) : null}
            </Dialog>
        </div>
    );
};

export default DialogPopupContainer;
