import Axios, { AxiosResponse } from 'axios';

// export const SERVER_ENDPOINT = 'http://localhost:3010';
export const SERVER_ENDPOINT = 'https://regapp.portaltempo.co.il'; // for prod
export const API_ENDPOINT = '/api/v1/';

export const getToken = (): string | null => {
    return localStorage.getItem('authToken') || null;
};

export interface ApiResponse<T = unknown> {
    status: number;
    statusText: string;
    message?: string;
    success?: boolean;
    data?: T;
}

interface BaseApiResponse {
    success: boolean;
    message?: string;
}

const parseRequestResponse = async <T extends BaseApiResponse>(
    request: Promise<AxiosResponse<T>>,
    description: string,
): Promise<ApiResponse<T>> => {
    try {
        const response = await request;
        const common_data = {
            status: response.status,
            statusText: response.statusText,
        };

        if (response.status === 200 || response.status === 201) {
            const result = response.data;

            if (result.success) {
                return { ...common_data, ...result };
            } else {
                return {
                    ...common_data,
                    ...result,
                    message: `${description} שגיע: ${result.message}`,
                };
            }
        } else if (response.status === 401) {
            // bad login data
            return {
                ...common_data,
                message: `Wrong user credentials or user session is expired! Please, re-login...`,
            };
        } else {
            return {
                ...common_data,
                message: `Server has responded with error status ${response.status} on getting ${description}: ${response.statusText}`,
            };
        }
    } catch (error) {
        return {
            status: 0,
            statusText: '',
            message: `Unexpected error while processing ${description}: ${error}`,
        };
    }
};

// Function to fetch data with GET method
export const fetchGet = async (
    url: string,
    description: string,
): Promise<ApiResponse> => {
    const token = getToken();
    return await parseRequestResponse(
        Axios.get(url, {
            withCredentials: true,
            headers: {
                'Access-Control-Allow-Origin': SERVER_ENDPOINT,
                Authorization: token ? `Bearer ${token}` : '',
            },
        }),
        description,
    );
};

export const fetchPost = async <T>(
    url: string,
    data: T,
    content_type: string = 'application/json',
    description: string = '',
): Promise<ApiResponse> => {
    const token = getToken();
    return await parseRequestResponse(
        Axios.post(url, data, {
            withCredentials: true,
            headers: {
                'Content-Type': content_type,
                'Access-Control-Allow-Origin': SERVER_ENDPOINT,
                Authorization: token ? `Bearer ${token}` : '',
            },
        }),
        description,
    );
};

export const fetchPut = async <T>(
    url: string,
    data: T,
    content_type: string = 'application/json',
    description: string = '',
): Promise<ApiResponse> => {
    const token = getToken();
    return await parseRequestResponse(
        Axios.put(url, data, {
            withCredentials: true,
            headers: {
                'Content-Type': content_type,
                'Access-Control-Allow-Origin': SERVER_ENDPOINT,
                Authorization: token ? `Bearer ${token}` : '',
            },
        }),
        description,
    );
};

export const fetchDelete = async <T>(
    url: string,
    data: T,
    content_type: string = 'application/json',
    description: string = '',
): Promise<ApiResponse> => {
    const token = getToken();
    return await parseRequestResponse(
        Axios.delete(url, {
            withCredentials: true,
            headers: {
                'Content-Type': content_type,
                'Access-Control-Allow-Origin': SERVER_ENDPOINT,
                Authorization: token ? `Bearer ${token}` : '',
            },
            data,
        }),
        description,
    );
};
