import {
    fetchGet,
    fetchPost,
    SERVER_ENDPOINT,
    ApiResponse,
    API_ENDPOINT,
} from './index';

import axios, { AxiosError } from 'axios';

interface UserSignInData {
    username: string;
    password: string;
}

interface Token {
    token: string;
}

export interface UserSignInResponse {
    token: string;
    user_display_name: string;
    user_email: string;
    user_nicename: string;
}

export const userSignIn = async (
    user_data: UserSignInData,
): Promise<ApiResponse<UserSignInResponse>> => {
    const tokenUrl = 'https://wp.portaltempo.co.il//wp-json/jwt-auth/v1/token';

    try {
        const response = await axios.post(tokenUrl, {
            username: user_data.username,
            password: user_data.password,
        });

        const tokenData = response.data.token;
        return {
            status: response.status,
            statusText: response.statusText,
            success: true,
            data: tokenData,
        };
    } catch (error: unknown) {
        const axiosError = error as AxiosError;

        return {
            status: axiosError.response?.status || 500,
            statusText:
                axiosError.response?.statusText || 'Internal Server Error',
            success: false,
            message: axiosError.message,
        };
    }
};

export const fetchVerifiedUser = async (
    token_data: Token,
): Promise<ApiResponse> => {
    return await fetchPost(
        `${SERVER_ENDPOINT}${API_ENDPOINT}usersRoute/validateToken`,
        token_data,
    );
};

export const userLogout = async (): Promise<ApiResponse> => {
    return await fetchGet(
        `${SERVER_ENDPOINT}/api/users/userLogout`,
        'UserLogout',
    );
};
