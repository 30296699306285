import React, { Suspense, ReactNode, lazy, useEffect, useState } from 'react';
import './assets/scss/general.scss';
import {
    RouterProvider,
    createBrowserRouter,
    Navigate,
} from 'react-router-dom';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { CacheProvider, ThemeProvider } from '@emotion/react';
import AlertPopupContainer from './helpers/AlertPopup';
import DialogPopupContainer from './helpers/DialogPopup';
import AppContext, { LoggedInUser } from './contexts/AppContext';
import theme from './styles/mui/CustomTheme';
import { UIProvider } from './contexts/UIContext';
import { LinearProgress } from '@mui/material';
import ErrorScreen from './screens/ErrorScreen';
import { fetchVerifiedUser } from './services/userApi';
import { getToken } from './services';

// Lazy load your screens
const Home = lazy(() => import('./screens/Home'));
const UserPanel = lazy(() => import('./screens/UserPanel'));
const AdminPanel = lazy(() => import('./screens/AdminPanel'));
const Login = lazy(() => import('./screens/Login'));
const Logout = lazy(() => import('./screens/Logout'));

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
    const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | undefined>(
        undefined,
    );
    const [loading, setLoading] = useState<boolean>(true);

    interface ProtectedRouteProps {
        children: ReactNode;
        loggedInUser: LoggedInUser | null | undefined; // Allow both null and undefined
    }

    const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
        children,
        loggedInUser,
    }) => {
        if (!loggedInUser || !Object.keys(loggedInUser).length) {
            return <Navigate to="/login" />;
        } else {
            return <>{children}</>;
        }
    };

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <Suspense fallback={<LinearProgress />}>
                    <Home />
                </Suspense>
            ),
            errorElement: <ErrorScreen />,
        },
        {
            path: '/login',
            element: (
                <Suspense fallback={<LinearProgress />}>
                    <Login />
                </Suspense>
            ),
            errorElement: <ErrorScreen />,
        },
        {
            path: '/logout',
            element: (
                <ProtectedRoute loggedInUser={loggedInUser}>
                    <Suspense fallback={<LinearProgress />}>
                        <Logout />
                    </Suspense>
                </ProtectedRoute>
            ),
            errorElement: <ErrorScreen />,
        },
        {
            path: '/admin-panel',
            element: (
                <ProtectedRoute loggedInUser={loggedInUser}>
                    <Suspense fallback={<LinearProgress />}>
                        <AdminPanel />
                    </Suspense>
                </ProtectedRoute>
            ),
            errorElement: <ErrorScreen />,
        },
        {
            path: '/panel',
            element: (
                <ProtectedRoute loggedInUser={loggedInUser}>
                    <Suspense fallback={<LinearProgress />}>
                        <UserPanel />
                    </Suspense>
                </ProtectedRoute>
            ),
            errorElement: <ErrorScreen />,
        },
    ]);

    const verifyUserSessionIsActive = async () => {
        try {
            const token = getToken();
            if (!token) {
                setLoading(false);
                return;
            }

            const verifiedToken = await fetchVerifiedUser({ token });
            if (verifiedToken.success && verifiedToken.data) {
                setLoggedInUser(verifiedToken.data as LoggedInUser);
            }
            setLoading(false);
        } catch (error) {
            console.error('error:', error);
        }
    };

    const appContextData = {
        loggedInUser,
        setLoggedInUser,
        loading,
        setLoading,
    };

    useEffect(() => {
        verifyUserSessionIsActive();
    }, []);

    return (
        <div className="App">
            <CacheProvider value={cacheRtl}>
                <ThemeProvider theme={theme}>
                    <UIProvider>
                        <AppContext.Provider value={appContextData}>
                            <RouterProvider router={router} />
                            <AlertPopupContainer />
                            <DialogPopupContainer />
                        </AppContext.Provider>
                    </UIProvider>
                </ThemeProvider>
            </CacheProvider>
        </div>
    );
}

export default App;
