import { createTheme } from '@mui/material';

const theme = createTheme({
    direction: 'rtl',
    palette: {
        primary: {
            main: '#3982e3',
            light: '#609BE8',
            dark: '#2D68B5',
        },
        primary_dark: {
            main: '#0d1857',
            light: '#2e3b8f',
            dark: '#020933',
            contrastText: '#edeffc',
        },
        gray: {
            50: '#edeffc',
            100: '#F5F6F7',
            700: '#19172b',
        },
    },
    typography: {
        fontFamily: 'Rubik, Arial',
        h1: {
            fontSize: 'max(34px, 3.6vw)',
            fontWeight: 700,
        },
        h2: {
            fontSize: 'max(28px, 2.4vw)',
            fontWeight: 800,
        },
        h3: {
            fontSize: 'max(22px, 2vw)',
            fontWeight: 700,
        },
        h5: {
            fontSize: 'max(17px, 1.2vw)',
            fontWeight: 400,
        },
        h6: {
            fontSize: 'max(16px, 1vw)',
            fontWeight: 500,
        },
        p: {
            fontSize: 'max(15px, 0.8vw)',
        },
        input: {
            fontSize: 'max(16px, 1vw)',
        },
        button: {
            fontSize: 'max(18px, 1.1vw)',
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.MuiFilled-root': {
                        margin: '16px 0',
                        backgroundColor: '#F5F6F7',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.MuiFilled': {
                        color: '#19172b',
                        fontSize: 'max(16px, 1vw)',
                        '&.Mui-focused': {
                            color: '#3982e3',
                        },
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    borderRadius: 'max(10px, 0.5vw)',
                    '&.Mui-focused': {
                        backgroundColor: '#e0e2eb',
                        borderColor: '#3982e3',
                    },
                    '&::before, &::after': {
                        borderBottom: 'unset !important',
                    },
                    '&:hover': {
                        backgroundColor: '#edeffc',
                        '&::before, &::after': {
                            borderBottom: 'unset !important',
                        },
                    },
                    '&.Mui-disabled': {
                        pointerEvents: 'none',
                    },
                },
            },
        },
    },
});

export default theme;
