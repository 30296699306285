import { createContext, Dispatch, SetStateAction } from 'react';

// Define the type for the context value
export interface LoggedInUser {
    role: string;
    employeeNumber: string;
    email: string;
    displayName: string;
}

export interface AppContextType {
    loggedInUser?: LoggedInUser;
    setLoggedInUser: Dispatch<SetStateAction<LoggedInUser | undefined>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export default AppContext;
