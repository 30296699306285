import { Snackbar } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { Alert } from '@mui/material';
import { useUIContext } from '../contexts/UIContext';

export interface AlertObject {
    duration: number;
    open: boolean;
    message: string;
    severity: 'error' | 'warning' | 'success' | 'info';
    call_after_close?: () => void | null;
}

export const ERROR_ALERT_OBJECT: AlertObject = {
    duration: 3000,
    open: true,
    message: '',
    severity: 'error',
    call_after_close: undefined,
};

export const WARNING_ALERT_OBJECT: AlertObject = {
    duration: 6000,
    open: true,
    message: '',
    severity: 'warning',
    call_after_close: undefined,
};

export const SUCCESS_ALERT_OBJECT: AlertObject = {
    duration: 3000,
    open: true,
    message: '',
    severity: 'success',
    call_after_close: undefined,
};

export const INFO_ALERT_OBJECT: AlertObject = {
    duration: 6000,
    open: true,
    message: '',
    severity: 'info',
    call_after_close: undefined,
};

export const HIDDEN_ALERT_OBJECT: AlertObject = {
    ...SUCCESS_ALERT_OBJECT,
    open: false,
    call_after_close: undefined,
};

const AlertPopupContainer: FC = () => {
    const { alertObject, setAlertObject } = useUIContext();

    const handleCloseAlert = (
        event: SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        const { call_after_close } = alertObject;
        setAlertObject({ ...alertObject, open: false });

        if (call_after_close) {
            call_after_close();
        }
    };

    return (
        <Snackbar
            open={alertObject.open}
            autoHideDuration={alertObject.duration}
            onClose={handleCloseAlert}
        >
            <Alert variant="filled" severity={alertObject.severity}>
                {alertObject.message}
            </Alert>
        </Snackbar>
    );
};

export default AlertPopupContainer;
