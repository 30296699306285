import React, {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
} from 'react';
import { AlertObject, HIDDEN_ALERT_OBJECT } from '../helpers/AlertPopup'; // Adjust the path
import { DialogObject, HIDDEN_DIALOG_OBJECT } from '../helpers/DialogPopup'; // Adjust the path

interface UIContextType {
    alertObject: AlertObject;
    setAlertObject: Dispatch<SetStateAction<AlertObject>>;
    dialogObject: DialogObject;
    setDialogObject: Dispatch<SetStateAction<DialogObject>>;
}

const UIContext = createContext<UIContextType | undefined>(undefined);

export const UIProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [alertObject, setAlertObject] =
        useState<AlertObject>(HIDDEN_ALERT_OBJECT);
    const [dialogObject, setDialogObject] =
        useState<DialogObject>(HIDDEN_DIALOG_OBJECT);

    return (
        <UIContext.Provider
            value={{
                alertObject,
                setAlertObject,
                dialogObject,
                setDialogObject,
            }}
        >
            {children}
        </UIContext.Provider>
    );
};

// Custom hook for using UI context
export const useUIContext = () => {
    const context = useContext(UIContext);
    if (context === undefined) {
        throw new Error('useUIContext must be used within a UIProvider');
    }
    return context;
};
